import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Label = styled('label', {
  variants: {
    $error: {
      true: {
        color: '$semanticErrorText',
      },
      false: {
        color: '$interfaceLabelPrimary',
      },
    },
  },

  display: 'flex',
  alignItems: 'center',
  fontFamily: 'var(--fonts-paragraph)',
  fontSize: 'var(--fontSizes-2)',
  fontWeight: 'var(--fontWeights-semibold)',
  lineHeight: 'var(--lineHeights-3)',
  marginBottom: 'var(--space-1)',
  color: 'var(--colors-interfaceLabelPrimary)',

  defaultVariants: {
    $error: false,
  },
});

export const Tag = styled('span', {
  color: '$interfaceLabelPrimary',
  textTransform: 'capitalize',
  marginLeft: '$05',
  fontSize: '$3',
});

export const ErrorText = styled('p', {
  fontSize: '$1',
  margin: '0',
  color: '$semanticErrorText',
});

export const HintText = styled('p', {
  fontSize: '0.75rem',
  color: '$interfaceLabelPrimary',
  margin: '0',
});
