import { styled } from '@hexa-ui/theme';
import 'react-datepicker/dist/react-datepicker.css';

export const Wrapper = styled('div', {
  width: '100%',
  margin: 0,

  '.react-datepicker__input-container input': {
    cursor: 'pointer',
    width: '100%',
    height: '48px',
    borderRadius: '0.5rem',
    position: 'relative',
    padding: '1em',
    transition: '0.35s ease-in-out',
    border: '1px solid $neutral30',
    fontFamily: 'var(--fonts-paragraph)',
    fontSize: 'var(--fontSizes-3)',
    lineHeight: 'var(--lineHeights-6)',

    '&::placeholder': {
      color: '$interfaceLabelSecondary',
    },

    '&:focus': {
      borderColor: '$neutral100',
      outline: 'none',
    },

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&[type="number"]': {
      '-moz-appearance': 'textfield',
    },

    variants: {
      error: {
        true: {
          border: '1px solid $semanticErrorText',
        },
        false: {
          border: '1px solid $neutral30',
        },
      },
    },

    defaultVariants: {
      error: 'false',
    },
  },

  '.react-datepicker__day--selected, .react-datepicker__day--range-end, .react-datepicker__day--range-start':
    {
      color: '$neutral100 !important',
      background: '$brandPrimaryBasis !important',
    },

  '.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range': {
    color: '$neutral100',
    background: '$brandPrimaryBackground',
  },
});
