import { Button, Grid, Input, Select } from '@hexa-ui/components';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from './DatePicker/DatePicker';
import { useAppFiltersContext } from './hooks/useAppFiltersContext';
import { Label } from './Label';

interface FiltersTableReportProps {
  handleFilter: () => void;
}

const FiltersTableReport = ({ handleFilter }: FiltersTableReportProps): JSX.Element => {
  const {
    paymentStatus,
    setPaymentStatus,
    orderStatus,
    setOrderStatus,
    deliveryDate,
    setDeliveryDate,
    orderId,
    setOrderId,
  } = useAppFiltersContext();

  const { formatMessage } = useIntl();

  const { Container, Item } = Grid;
  const { Option } = Select;

  const paymentStatusOptions = ['CAPTURED', 'CANCELLED'];

  const orderStatusOptions = [
    'CANCELLED',
    'CONFIRMED',
    'DELIVERED',
    'DENIED',
    'INVOICED',
    'IN_TRANSIT',
    'PARTIAL_DELIVERY',
    'PENDING',
    'PENDING_CANCELATION',
    'PENDING_PAYMENT',
    'PLACED',
    'MODIFIED',
  ];

  const handleClearFilters = () => {
    setPaymentStatus('');
    setOrderStatus('');
    setDeliveryDate(null);
    setOrderId('');
  };

  const allFiltersIsEmpty = (): boolean => {
    return !paymentStatus && !orderStatus && !deliveryDate && !orderId;
  };

  useEffect(() => {
    if (allFiltersIsEmpty()) {
      handleFilter();
    }
  }, [paymentStatus, orderStatus, deliveryDate, orderId]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFilter();
      }}
    >
      <Container style={{ padding: '8px 16px', width: '100%' }}>
        <Item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          style={{
            marginTop: 8,
          }}
        >
          <Select.Root
            data-testid="select-order-status"
            label={formatMessage({ id: 'table.filters.orderStatus' })}
            placeholder={formatMessage({ id: 'table.filters.selectValueOption' })}
            defaultValue={orderStatus}
            value={orderStatus}
            onChange={(value) => {
              setOrderStatus(value);
            }}
          >
            <Option value="">{formatMessage({ id: 'table.filters.selectValueOption' })}</Option>
            {orderStatusOptions.map((status) => (
              <Option key={status} value={status} data-testid={`order-status-${status}`}>
                {status}
              </Option>
            ))}
          </Select.Root>
        </Item>

        <Item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          style={{
            marginTop: 8,
          }}
        >
          <Select.Root
            data-testid="select-payment-status"
            label={formatMessage({ id: 'table.filters.paymentStatus' })}
            placeholder={formatMessage({ id: 'table.filters.selectValueOption' })}
            defaultValue={paymentStatus}
            value={paymentStatus}
            onChange={(value) => {
              setPaymentStatus(value);
            }}
          >
            <Option value="">{formatMessage({ id: 'table.filters.selectValueOption' })}</Option>
            {paymentStatusOptions.map((status) => (
              <Option key={status} value={status} data-testid={`payment-status-${status}`}>
                {status}
              </Option>
            ))}
          </Select.Root>
        </Item>

        <Item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          style={{
            marginTop: 8,
          }}
        >
          <Label
            id="deliveryDate"
            data-testid="select-delivery-date"
            label={formatMessage({ id: 'table.filters.deliveryDate' })}
          >
            <DatePicker
              data-testid="select-delivery-date-calendar"
              selected={!!deliveryDate ? new Date(deliveryDate) : null}
              isDateRange={false}
              placeholder={formatMessage({ id: 'table.filters.deliveryDate' })}
              onChange={(value: Date) => {
                setDeliveryDate(value);
              }}
            />
          </Label>
        </Item>

        <Item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          style={{
            marginTop: 8,
          }}
        >
          <Input
            id="order-id"
            data-testid="set-order-id"
            label={formatMessage({ id: 'table.filters.orderId' })}
            placeholder={formatMessage({ id: 'table.filters.orderId' })}
            defaultValue={orderId}
            value={orderId}
            // @ts-ignore
            onChange={(e) => setOrderId(e.target.value)}
            width="100%"
          />
        </Item>

        <Item
          xl={3}
          style={{
            display: 'flex',
            alignItems: 'end',
            marginTop: 8,
          }}
        >
          <Button
            data-testid="filter-button"
            size="medium"
            type="submit"
            style={{
              marginRight: 16,
            }}
          >
            {formatMessage({ id: 'table.filters.filterButton' })}
          </Button>

          <Button
            data-testid="clear-filters-button"
            size="medium"
            type="button"
            onClick={handleClearFilters}
            disabled={allFiltersIsEmpty()}
          >
            {formatMessage({ id: 'table.filters.clearFilterButton' })}
          </Button>
        </Item>
      </Container>
    </form>
  );
};

export default FiltersTableReport;
