import { usePreferredLanguage } from 'admin-portal-shared-services';
import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import { flattenObject } from '../utils/objects';
import MessageMap from './i18n.d';

import enUS from './languages/en-US';
import es419 from './languages/es-419';
import ptBR from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
  const preferredLanguage = usePreferredLanguage();

  const mergedMessages = mergeMessages(messages, preferredLanguage);

  return (
    <NativeProvider
      locale={preferredLanguage}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
}

export default IntlProvider;
