import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  table: {
    header: {
      createdDate: 'Data de criação',
      deliveryDate: 'Data de entrega',
      orderStatus: 'Status do pedido',
      orderId: 'ID do pedido',
      parentOrderNumber: 'Número do pedido original',
      deliveryCenterId: 'ID do centro de distribuição',
      authorizationExpiresAt: 'Vencimento da autorização',
      paymentStatus: 'Status do pagamento',
      preAuthAmount: 'Valor pré-autorizado',
      captureAmount: 'Valor capturado',
      transactionReferenceID: 'Código de referência da transação',
      invoiceAmount: 'Valor da fatura',
      invoiceStatus: 'Status da fatura',
    },
    filters: {
      paymentStatus: 'Status do pagamento',
      orderStatus: 'Status do pedido',
      deliveryDate: 'Data de entrega',
      orderId: 'ID do pedido',
      filterButton: 'Filtrar',
      clearFilterButton: 'Limpar filtros',
      selectValueOption: 'Selecione uma opção',
    },
    loading: 'Carregando dados',
    empty: 'Nenhum pedido encontrado',
  },
};

export default ptBR;
