import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Report from './pages/report';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/bees-pay-dashboard"
          element={<Report />}
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
