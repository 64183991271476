import { useContext } from 'react';
import { AppFiltersContext } from '../contexts/appFiltersContext';

function useAppFiltersContext() {
  const useAppFiltersContextTemp = useContext(AppFiltersContext);

  return useAppFiltersContextTemp;
}

export { useAppFiltersContext };
