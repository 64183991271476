import { BasePath } from '../constants/BasePath';

const ENV: string | null = JSON.parse(localStorage.getItem('portal-config'))?.ENV || 'sit';

export const getBasePath = (host = ENV) => {
  switch (host.toLowerCase()) {
    case 'dev':
      return BasePath.paymentReportServiceDEV;
    case 'qa':
      return BasePath.paymentReportServiceSIT;
    case 'uat':
      return BasePath.paymentReportServiceUAT;
    default:
      return BasePath.paymentReportServicePROD;
  }
};
