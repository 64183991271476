import MessageMap from '../i18n.d';

const es419: MessageMap = {
  table: {
    header: {
      createdDate: 'Created date',
      deliveryDate: 'Delivery date',
      orderStatus: 'Order status',
      orderId: 'Order ID',
      parentOrderNumber: 'Parent order number',
      deliveryCenterId: 'Delivery center ID',
      authorizationExpiresAt: 'Authorization expires',
      paymentStatus: 'Payment status',
      preAuthAmount: 'Pre-auth amount',
      captureAmount: 'Capture amount',
      transactionReferenceID: 'Transaction reference ID',
      invoiceAmount: 'Invoice amount',
      invoiceStatus: 'Invoice status',
    },
    filters: {
      paymentStatus: 'Payment status',
      orderStatus: 'Order status',
      deliveryDate: 'Delivery date',
      orderId: 'Order ID',
      filterButton: 'Filter',
      clearFilterButton: 'Clear filters',
      selectValueOption: 'Select a value',
    },
    loading: 'Loading data',
    empty: 'No order found',
  },
};

export default es419;
