import { Children, cloneElement, useMemo } from 'react';
import { LabelProps } from './Label.props';
import * as Styled from './Label.style';

const Label = ({ children, ...props }: LabelProps) => {
  const componentID = useMemo(() => props.id, [props.id]);
  const hasError = !!props.errorText;

  return (
    <Styled.Container>
      <Styled.Label $error={hasError} htmlFor={componentID} id={`lbl_${componentID}`}>
        {props.label} {props.isRequired && <Styled.Tag>*</Styled.Tag>}{' '}
      </Styled.Label>
      {props.hintText && <Styled.HintText>{props.hintText}</Styled.HintText>}
      {hasError && <Styled.ErrorText>{props.errorText}</Styled.ErrorText>}
      {children &&
        Children.map(children, (child) =>
          cloneElement(child, { id: componentID, hasError, isRequired: props.isRequired })
        )}
    </Styled.Container>
  );
};

export default Label;
