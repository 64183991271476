import DatePickerComponent from 'react-datepicker';
import { Wrapper } from './DatePicker.style';
import { DatePickerProps } from './DataPicker.props';

const DatePicker = (props: DatePickerProps) => {
  return (
    <Wrapper data-testid="datepicker">
      <DatePickerComponent
        id={String(props.id)}
        selected={props.selected}
        startDate={props.startDate}
        endDate={props.endDate}
        disabledKeyboardNavigation={true}
        selectsRange={props.isDateRange}
        onChange={(date) => props.onChange?.(date)}
        placeholderText={props.placeholder}
        autoComplete="off"
        minDate={props.minDate}
        disabled={props.disabled}
        dateFormat="dd/MM/yyyy"
      />
    </Wrapper>
  );
};

export default DatePicker;
