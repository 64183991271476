export class ReportConfig {
  static tableHeader(formatMessage: Function) {
    return [
      {
        Header: formatMessage({ id: 'table.header.createdDate' }),
        accessor: 'createDate',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.deliveryDate' }),
        accessor: 'deliveryDate',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.orderStatus' }),
        accessor: 'orderStatus',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.orderId' }),
        accessor: 'orderId',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.parentOrderNumber' }),
        accessor: 'parentOrderNumber',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.deliveryCenterId' }),
        accessor: 'deliveryCenterId',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.authorizationExpiresAt' }),
        accessor: 'authorizationExpiresAt',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.paymentStatus' }),
        accessor: 'paymentStatus',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.preAuthAmount' }),
        accessor: 'preAuthAmount',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.captureAmount' }),
        accessor: 'captureAmount',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.transactionReferenceID' }),
        accessor: 'transactionReferenceId',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.invoiceAmount' }),
        accessor: 'invoiceAmount',
        disableSortBy: true,
      },
      {
        Header: formatMessage({ id: 'table.header.invoiceStatus' }),
        accessor: 'invoiceStatus',
        disableSortBy: true,
      },
    ];
  }
}
