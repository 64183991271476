import { AppFiltersProvider } from '../components/contexts/appFiltersContext';
import TableReport from '../components/tableReport';

const Report = (): JSX.Element => {
  return (
    <AppFiltersProvider>
      <TableReport />
    </AppFiltersProvider>
  );
};

export default Report;
