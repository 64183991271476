import { Grid, Table } from '@hexa-ui/components';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Api from '../Api/Api';
import { getBasePath } from '../config/host';
import { ReportConfig } from '../constants/ReportConfig';
import { concatURL } from '../helpers/concatURL';
import { currencyFormat } from '../helpers/currencyFormat';
import FiltersTableReport from './filtersTableReport';
import { useAppFiltersContext } from './hooks/useAppFiltersContext';

const TableReport = (): JSX.Element => {
  const [tableContent, setTableContent] = useState(Array);
  const [tableLoad, setTalbeLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  const { formatMessage } = useIntl();
  const authentication = useAuthenticationService();
  const { paymentStatus, orderStatus, deliveryDate, orderId } = useAppFiltersContext();

  const { language: language } = authentication.getUserCountryAndLanguage();

  const { Container, Item } = Grid;

  const buildTableData = (item) => ({
    createDate: !!item.order.createdDate ? item.order.createdDate.split('T')[0] : '-',
    deliveryDate: item.order.deliveryDate ?? '-',
    orderStatus: item.order.status ?? '-',
    orderId: item.order.id ?? '-',
    parentOrderNumber: item.order.parentOrderNumber ?? '-',
    deliveryCenterId: item.order.deliveryCenterId ?? '-',
    authorizationExpiresAt: !!item.authorizationExpiresAt
      ? item.authorizationExpiresAt.split('T')[0]
      : '-',
    paymentStatus: item.paymentStatus ?? '-',
    preAuthAmount: currencyFormat(item.preAuthorizedAmount, language, 'BRL'),
    captureAmount: currencyFormat(item.capturedAmount, language, 'BRL'),
    transactionReferenceId: item.transactionReferenceId ?? '-',
    invoiceAmount:
      item?.invoices.length === 0
        ? currencyFormat(0, language, 'BRL')
        : currencyFormat(item.invoices[0].total, language, 'BRL'),
    invoiceStatus: item?.invoices.length === 0 ? '-' : item.invoices[0].status,
  });

  const getReportData = async () => {
    setTalbeLoad(true);

    try {
      const deliveryDateFormated = !!deliveryDate
        ? formatISO(new Date(deliveryDate), {
            representation: 'date',
          })
        : '';

      const params = new URLSearchParams();

      params.append('page', String(page));
      params.append('pageSize', String(pageSize));
      paymentStatus && params.append('paymentStatus', paymentStatus);
      orderStatus && params.append('orderStatus', orderStatus);
      deliveryDateFormated && params.append('deliveryDate', deliveryDateFormated);
      orderId && params.append('orderId', orderId);

      const response = await Api.get({
        url: concatURL(`${getBasePath()}/payment-orders`, params.toString()),
      });

      const responseFormated = response.data.content.map((item) => {
        return {
          ...buildTableData(item),
        };
      });

      setTotalItems(response.data.pagination.totalItems);
      setTableContent([...responseFormated]);
      setTalbeLoad(false);
    } catch (err) {
      setTotalItems(0);
      setTableContent([]);
      setTalbeLoad(false);
    }
  };

  useEffect(() => {
    getReportData();
  }, [page, pageSize]);

  return (
    <>
      <FiltersTableReport
        handleFilter={() => {
          setPage(1);

          if (page === 1) {
            getReportData();
          }
        }}
      />

      <Container style={{ padding: '16px 16px', width: '100%' }}>
        <Item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Table
            loading={tableLoad}
            loadingMessage={formatMessage({ id: 'table.loading' })}
            emptyMessage={formatMessage({ id: 'table.empty' })}
            columns={ReportConfig.tableHeader(formatMessage)}
            data={tableContent}
            pagination={{
              current: page,
              pageSize: pageSize,
              pageSizeOptions: [10, 25, 50],
              showPageSizeSelector: true,
              total: totalItems,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </Item>
      </Container>
    </>
  );
};

export default TableReport;
