import React, { createContext } from 'react';
import { usePersistedState } from '../hooks/usePersistedState';

export interface AppFiltersContextData {
  paymentStatus: string;
  orderStatus: string;
  deliveryDate: Date;
  orderId: string;
  setPaymentStatus: React.Dispatch<React.SetStateAction<string>>;
  setOrderStatus: React.Dispatch<React.SetStateAction<string>>;
  setDeliveryDate: React.Dispatch<React.SetStateAction<Date>>;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
}

export const AppFiltersContext = createContext({} as AppFiltersContextData);

export const AppFiltersProvider = ({ children }) => {
  const [paymentStatus, setPaymentStatus] = usePersistedState('paymentStatus', '');
  const [orderStatus, setOrderStatus] = usePersistedState('orderStatus', '');
  const [deliveryDate, setDeliveryDate] = usePersistedState('deliveryDate', null);
  const [orderId, setOrderId] = usePersistedState('orderId', '');

  return (
    <AppFiltersContext.Provider
      value={{
        paymentStatus,
        orderStatus,
        deliveryDate,
        orderId,
        setPaymentStatus,
        setOrderStatus,
        setDeliveryDate,
        setOrderId,
      }}
    >
      {children}
    </AppFiltersContext.Provider>
  );
};
